import React from 'react';
import feature1 from '../zedcube/painoient.jpeg';
import feature2 from '../zedcube/painoient1.jpeg';
import feature3 from '../zedcube/pantro.jpeg';
import feature4 from '../zedcube/pantrolarge.jpeg';
import feature5 from '../zedcube/aceclo.jpeg';
import feature6 from '../zedcube/painoient.jpeg';

const Categories = () => {
	const content = [
		{
			image: feature1,
			title: `Ointment-Gel`
		},
		{
			image: feature2,
			title: `Ointment`
		},
		{
			image: feature3,
			title: `Injection`
		},
		{
			image: feature4,
			title: `Injections`
		},
		{
			image: feature5,
			title: `Pain Killer`
		},
		{
			image: feature6,
			title: `Ointment`
		}
	];

	return (
		<div className="">
			<div className="feature-heading mt-5">
				<h2 className="mb-4">Arriving Soon</h2>
			</div>

			<ul id="autoWidth" className="row">
				{content.map((item, index) => (
					<li className="item" key={index}>
						<div className="feature-box">
							<img src={item.image} alt="Featured" />
						</div>
						<span className="mt-3">{item.title}</span>
					</li>
				))}
			</ul>
		</div>
	);
};

export default Categories;
