import React from 'react';
import Slider from 'react-animated-slider';
import '../horizontal.css';
import banner2 from '../zedcube/slide1.gif';
import banner1 from '../zedcube/slide2.gif';
import banner3 from '../zedcube/huge.gif';
import banner4 from '../zedcube/slide4.jpg';

const Header = () => {
	const content = [
		{
			image: banner1,
			title: `30% off With 10000+ orders`,
			description: 'LIMITED OFFER',
			button: 'SHOP NOW'
		},
		{
			image: banner2,
			title: `10%-20% off for bulk orders `,
			description: 'LIMITED OFFER',
			button: 'SHOP NOW'
		},
		{
			image: banner3,
			title: `20% off on Pantrozed`,
			description: 'EXCLUSIVE OFFER',
			button: 'SHOP NOW'
		},
		{
			image: banner4,
			title: `Why buy Discrete ? Save with our Subscription`,
			description: 'PREMIUM OFFER',
			button: 'VISIT NOW'
		}
	];

	return (
		<Slider autoplay={3000}>
			{content.map((item, index) => (
				<div key={index} style={{ background: `url(${item.image}) no-repeat center center` }}>
					<div className="slider-content">
						<p className="lead">{item.description}</p>
						<h2 className="mb-4 banner-text">{item.title}</h2>
						<button
							className="btn btn-danger banner-button"
							onClick={() => {
								window.scrollTo(0, 800);
							}}
						>
							{item.button}
						</button>
					</div>
				</div>
			))}
		</Slider>
	);
};

export default Header;
